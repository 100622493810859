import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { Col, Row, Accordion } from 'react-bootstrap';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyMap } from 'services/coins/common';
import PortfolioRowData from 'pages/common/portfolioRow/PortfolioRowData';
import {
  manageBotsData,
  getCurrentAndPreviousMinuteIsoStrings,
  hideCapitalAlignement
} from 'staticData/common';
import classNames from 'classnames';
import { calculateCoinsValue } from 'services/coins/functions';

const ActiveUserRow = ({ user, activeKey }) => {
  const {
    config: { lang, currency },
    coinValues
  } = useContext(AppContext);
  const { user_id: userID, name, surname, email, bots } = user;
  const [loaded, setLoaded] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [dynamicCoins, setDynamicCoins] = useState({});
  const exchange = 'binance';

  const getColorAndCaret = num => {
    if (Number(num) < 0) return ['text-danger', 'caret-down'];
    if (Number(num) > 0) return ['text-success', 'caret-up'];
    return ['text-dark', 'minus'];
  };

  const calcPerformance = () => {
    return bots.reduce((totalPerformance, bot) => {
      const allocationChanges = bot.allocation_changes;
      const addedCapital = (allocationChanges || []).reduce(
        (sum, item) => sum + item.metadata?.eqv[currencyMap[currency]] || 0,
        0
      );
      const invested = bot.initial_eqv[currencyMap[currency]] + addedCapital;
      const managed = Object.keys(dynamicCoins).length
        ? calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
        : bot.last_stat.eqv[currencyMap[currency]];

      return totalPerformance + (managed - invested);
    }, 0);
  };

  const getWalletStats = async () => {
    var params = { exchange: exchange, virtual: false, user_id: userID };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getWallet',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      let data = walletStatsResponse.data;
      if (
        data[exchange]?.all_timepoints &&
        Object.keys(data[exchange]?.all_timepoints).length == 0
      ) {
        walletStatsResponse.data[exchange].all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[0]
        ] = { usd_value: 0 };
        walletStatsResponse.data[exchange].all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[1]
        ] = { usd_value: 0 };
      }
      hideCapitalAlignement(walletStatsResponse.data, currencyMap[currency]);
      return walletStatsResponse.data;
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const getPortfolioData = async () => {
    const localWallet = await getWalletStats();
    const { localPortfolios } = manageBotsData(
      localWallet,
      exchange,
      currencyMap[currency]
    );
    setPortfolios(localPortfolios);
    setLoaded(true);
  };

  const performance = calcPerformance();

  useEffect(() => {
    if (activeKey === userID) getPortfolioData();
    // init portfolio list
    return () => setPortfolios([]);
  }, [activeKey]);

  useEffect(() => {
    const checkCoins = () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
        return true;
      }
      return false;
    };
    if (!checkCoins()) {
      const intervalCoinsData = setInterval(() => {
        if (checkCoins()) {
          clearInterval(intervalCoinsData);
        }
      }, 1000);
      return () => clearInterval(intervalCoinsData);
    }
  }, []);

  return (
    <Accordion.Item eventKey={userID.toString()} key={userID}>
      <Accordion.Header>
        <Row className="w-100">
          <Col sm={9}>
            <Row>
              <Col sm={5} as={Flex} alignItems="center">
                <FontAwesomeIcon icon="user" size="xs" className="me-2" />
                <p className="mb-0">{`${name} ${surname}`}</p>
              </Col>
              <Col as={Flex} alignItems="center" className="mt-2 mt-sm-0">
                <FontAwesomeIcon icon="at" size="xs" className="me-2" />
                <p className="mb-0">{email}</p>
              </Col>
            </Row>
          </Col>
          <Col className="mt-2 mt-sm-0">
            <div>
              <span className="me-2">
                <FontAwesomeIcon
                  icon="arrow-up"
                  size="xs"
                  className="text-success"
                />
                <FontAwesomeIcon
                  icon="arrow-down"
                  size="xs"
                  className="text-danger mt-1"
                />
              </span>
              <b className={getColorAndCaret(performance)[0]}>
                {Number(performance) !== 0 && (
                  <span className="me-1">
                    <FontAwesomeIcon icon={getColorAndCaret(performance)[1]} />
                  </span>
                )}
                {currency}
                {Math.abs(Number(performance).toFixed(2))}
              </b>
            </div>
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body className="p-0">
        {!loaded ? (
          <PortfolioRowData dynamicCoins={dynamicCoins} />
        ) : portfolios.length ? (
          portfolios.map((portfolio, index) => (
            <div key={index} className={classNames('', { 'mt-3': index > 0 })}>
              <PortfolioRowData
                portfolioData={portfolio}
                dynamicCoins={dynamicCoins}
                clusterChildID={userID}
              />
            </div>
          ))
        ) : (
          <p className="m-2">Non è presente nessun Portfolio</p>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

ActiveUserRow.propTypes = {
  user: PropTypes.object,
  activeKey: PropTypes.string
};

export default ActiveUserRow;
